/*background profile image css */
.lodo-div {
  margin-bottom: 40px;
}
.uploadBanner {
  height: 269px;
  width: 100%;
}
.backgroud-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* profile image css */
.image-1 {
  top: 0px;
  margin-top: 156px;
  height: 111px;
  width: 113px;
  /* overflow: hidden; */
  /* object-fit: cover !important; */
  background-size: cover !important; /* Cover the entire container */
  background-position: center !important;
  border-radius: 5px !important;
}

.image-upload {
  height: 111px !important;
  width: 113px !important;
  object-fit: cover !important;
  /* overflow: hidden; */
}

.add-restro-picture-container {
  position: relative;
}
.add-restro-picture-container:hover .change-picture {
  display: block;
  cursor: pointer;
}
.change-picture {
  transition: 1s ease-in;
  display: none;
  position: absolute;
  top: 40%;
  left: 40%;
}
.change-picture i {
  font-size: 5em !important;
}
.image-upload1 {
  border-radius: 2px;
}

.add-restro-picture-container:hover .change-picture1 {
  display: block;
  cursor: pointer;
}

.change-picture1 {
  transition: 1s ease-in;
  display: none;
  position: absolute;
  top: 40%;
  left: 40%;
}
.change-picture1 i {
  font-size: 2em !important;
}
