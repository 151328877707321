.how-work-wrap {
    background: linear-gradient(180deg, rgba(255, 206, 103, 0.22) -42.47%, rgba(253, 237, 202, 0.00) 100%);
    padding: 80px 0;
}
.how-work-wrap .icon-wrapp h3 {
    color: #F17228;
    font-weight: 700;
    line-height: 112%;
    margin: 0;
    font-size: 35px;
}
.how-work-wrap .icon-wrapp .icon-infoo {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 30px;
}
.how-work-wrap .icon-infoo .icon-single-wrap .icon-desc p.desc-bold {
    font-size: 22px;
    font-weight: 700;
    color: #434343;
    line-height: 120%;
    margin-bottom: 5px;
}
.how-work-wrap .icon-infoo .icon-single-wrap .icon-desc .desc-light {
    font-size: 16px;
    font-weight: 400;
    color: #9E9E9E;
}
.cstm-home-banner .transform-center h3.text-custom-white {
    font-size: 16px;
    color: #504F4F;
}
.cstm-home-banner .transform-center h1.text-custom-white {
    margin-bottom: 8px;
}
.cstm-home-banner .find-food-wrap .find-text,
.cstm-home-banner .find-food-wrap .find-food-infoo {
    padding: 0;
}
.cstm-home-banner .find-food-wrap {
    background: #fff;
    border-radius: 16px;
}
.cstm-home-banner .find-food-wrap .find-text p {
    margin: 0;
    font-size: 18px;
    color: #F17228;
    text-transform: capitalize;
}
.cstm-home-banner .find-food-wrap .find-text {
    padding: 24px 24px 20px;
    border-bottom: 1px solid #EEE;
}
.cstm-home-banner .find-food-wrap .find-food-infoo {
    padding: 24px;
}
.find-food-wrap .find-food-infoo .form-infoo .input-wrap .form-control {
    border: unset;
    background: unset;
    color: #9E9E9E;
    padding: 0;
    height: auto;
    min-height: 30px;
}
.find-food-wrap .find-food-infoo .form-infoo {
    margin: 0;
    align-items: center;
    gap: 40px;
}
.find-food-wrap .find-food-infoo .form-infoo .input-wrap {
    display: flex;
    align-items: center;
    background: #F5F5F5;
    border-radius: 8px;
    padding: 7px 0 7px 16px;
    gap: 12px;
}
.find-food-wrap .find-food-infoo .form-infoo .input-wrap i {
    color: #FF7474;
    font-size: 18px;
}   
.find-food-wrap .find-food-infoo .form-infoo .form-btn {
    padding: 0;
    border-radius: 8px;
    background: linear-gradient(96deg, #FF7A7A -39.64%, #F65900 135.31%);
    position: relative;
}
.find-food-wrap .find-food-infoo .form-infoo .form-btn button {
    padding: 12px;
    width: 100%;
    background: unset;
    border: unset;
}
.find-food-wrap .find-food-infoo .form-infoo .form-btn i {
    color: #fff;
    font-size: 14px;
}
.cstm-home-banner .swiper-slide.slide-item {
    height: 600px;
}
.restaurent-ad .deals-banner-wrap {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-bottom: 60px;
}
.restaurent-ad .deals-banner-wrap .upper-banner, 
.restaurent-ad .deals-banner-wrap .btm-banner-wrap {
    border-radius: 16px;
    box-shadow: 0px 27px 82px 0px rgba(124, 124, 124, 0.28), 0px 14px 15px 0px rgba(124, 124, 124, 0.06);
    overflow: hidden;
    align-items: center;
}
.restaurent-ad .deals-banner-wrap .up-banner-img {
    padding: 0;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo {
    padding: 40px;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    align-items: center;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-cont h2 {
    font-size: 32px;
    font-weight: 700;
    color: #353535;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-cont h2 span {
    color: #fc8019 ;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-cont p {
    color: rgba(97, 97, 97, 0.69);
    font-size: 16px;
    line-height: 140%;
    margin: 0;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn {
    width: 100%;
    text-align: center;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn button {
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(93deg, #FFB800 -47.72%, #FF8A00 136.81%);
    box-shadow: 0px 14px 32px 0px rgba(255, 178, 14, 0.29), 0px 5px 8px 0px rgba(222, 151, 0, 0.24);
    color: #fff;
    font-size: 14px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: 700;
    gap: 10px;
}
.restaurent-ad .deals-banner-wrap .up-banner-img img {
    border-radius: 0;
    width: 100%;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn button i {
    font-size: 10px;
}
.restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn button a {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.find-food-wrap .find-food-infoo .form-infoo .form-btn button a {
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.custom-header .location-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.custom-header .location-wrap .deliver {
    font-size: 14px;
    font-weight: 700;
    color: #424242;
}
.custom-header .location-wrap .loc-txt {
    color: #424242;
    font-weight: 400;
}
.custom-header .header-wrapper-main {
    /* padding: 20px 0; */
    align-items: center;
    height: 100%;
}
.custom-header .search-bar a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.custom-header .search-bar a i {
    color: #fc8019 ;
}
.custom-header .search-bar a p {
    margin: 0;
    color: #424242;
    font-weight: 700;
}
.custom-header .login-btn button {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 20px 40px 0px rgba(255, 174, 0, 0.29), 0px 5px 10px 0px rgba(255, 174, 0, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 24px;
    color: #fc8019 ;
    font-weight: 700;
}
.custom-header .login-btn {
    display: flex;
    justify-content: flex-end;
}
.custom-header .logo-wrap img {
    max-width: 110px;
    transition: 0.4s ease-out;
}
.custom-header .location-wrap i {
    color: #fc8019 ;
}
.custom-header .location-wrap .loc-info {
    font-weight: 700;
    color: #424242;
}

.header-mob-wrapper-main {
    display: none;
}
.header-wrapper-main {
    display: flex;
}

.your-mind-wrap .swiper-button-prev {
    position: absolute;
    top: 38px;
    left: unset;
    right: 50px;
    display: flex;
}
.your-mind-wrap .swiper-button-next {
    position: absolute;
    top: 38px;
    z-index: 99;
    display: flex;
}
.your-mind-wrap .swiper-container {
    position: relative;
    padding: 40px 0 !important;
}
.product-box .product-caption .product-title {
    font-size: 16px;
    font-weight: 600;
}
.product-box .product-img .img-fluid {
    border-radius: 8px;
}
.most-popular.section-padding {
    background: linear-gradient(180deg, rgb(255 206 103 / 9%) -51.47%, rgba(253, 237, 202, 0) 100%);
}
.product-box .product-footer-2 {
    padding: 8px 10px;
}
.product-box button.buy-btnn {
    width: 100%;
    height: 45px;
    background: rgba(241, 114, 40, 1);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #fff;
}
.product-box .product-caption .title-box {
    margin-bottom: 0;
}
.product-view-filter .product-view-filter-btn:hover {
    background: rgba(255, 154, 14, 1) !important;
    transition: 0.3s ease-out;
}
.product-view-filter .product-view-filter-btn:hover p,
.product-view-filter .product-view-filter-btn:hover i {
    color: #fff !important;
}
.discount span.text-success {
    color: #fc8019  !important;
}
.product-view-filter-btn i {
    margin-left: 4px;
}

.custom-header .logout-btn {
    display: flex;
    justify-content: flex-end;
}
.logout-btn .user-profile-header {
    position: relative;
    width: auto;
    cursor: pointer;
    align-items: center;
    padding: 0 20px;
}
.logout-btn .user-profile-header i.fas.fa-user {
    font-size: 20px;
}
.logout-btn .user-profile-header:hover .user-info-wrap {
    display: block !important;
}
.user-info-wrap {
    position: absolute;
    right: 0;
    top: 82px;
    z-index: 99;
    background: #fff;
    width: 200px;
    padding: 20px;
    animation: headuser .3s .3s ease-out forwards;
    box-shadow: 0 2px 20px 0 #93959f;
    opacity: 0;
    border-top: 2px solid #fc8019;
}
.user-info-wrap ul {
    display: flex;
    flex-wrap: wrap;
}
.user-info-wrap ul li {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    width: 50%;
    align-items: center;
    padding: 5px;
}
.user-info-wrap ul li a {
    border-radius: 2px;
    padding: 6px;
    /* background-color: #d3d3d338; */
    display: block;
    text-align: center;
}
.user-info-wrap ul li a i{
    font-size: 20px;
}
.user-info-wrap ul li:hover a{
    color: #F17228;
    border-radius: 2px;
    padding: 6px;
    background-color: #f172280c;
}
.user-info-wrap .info-icon {
    position: absolute;
    right: 41px;
    top: -12px;
}
.user-info-wrap .info-icon:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    top: 5px;
    box-shadow: -3px -4px 9px -4px rgba(40, 44, 63, .5);
    background-color: #fff;
    border: 2px solid #fc8019;
    border-right: none;
    border-bottom: none;
    -ms-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
}
.custom-header .logo-wrap img:hover {
    transform: scale(1.04);
    transition: 0.4s ease-out;
}
.custom-header {
    height: 82px;
    box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);
}
.search-food-wrap {
    padding-top: 40px;
}
.footer-Add-Cart {
    z-index: 99;
}
section.store-page-wrapper {
    padding-top: 60px;
}
.restaurent-product-list {
    box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);
    border-radius: 20px;
    transition: 0.5s;
}
.restaurent-product-list:hover {
    box-shadow: 0 15px 40px -20px rgba(40, 44, 63, .15);
    transform: translateY(-4px);
}
.restaurent-product-list .restaurent-product-detail {
    padding: 16px;
}
.restaurent-logo {
    box-shadow: unset;
}
.promocodeimg img {
    filter: brightness(0.4);
}
.logout-btn .user-profile-header img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.product-view-filter-btn.active {
    background: #ff9a0e !important;
    border: unset;
}
.product-view-filter-btn.active p {
    color: #fff !important;
}

.custom-header .main-header-wrapper {
    padding: 0 80px;
}
.header-wrapper-main .log-loc-wrap,
.log-loc-wrap .logo-wrap,
.log-loc-wrap .location-wrap,
.header-wrapper-main .acc-info-wrap,
.header-wrapper-main .acc-info-wrap .col-md-3,
.acc-info-wrap .col-md-1,
.acc-info-wrap .col-md-2  {
    padding: 0;
}
.header-wrapper-main .log-loc-wrap,
.header-wrapper-main .acc-info-wrap {
    height: 100%;
    align-items: center;
}
.acc-info-wrap .noti-wrap img {
    width: 26px;
    height: 26px;
}
.acc-info-wrap .noti-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-wrapper .user-profile-header {
    padding: 0 10px;
}
.cart-wrapper .user-profile-header i {
    margin-right: 5px;
    font-size: 15px;
}
.cart-wrapper .user-profile-header .badge {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -16px;
    font-size: 10px;
    top: -12px;
    background: #F65900 !important;
}
.cart-wrapper .user-profile-header a {
    font-size: 14px;
    font-weight: 500;
}
.user-info-wrap.cart-wrapper {
    width: 280px;
}
.cart-wrapper .cat-product-box:not(:last-child) {
    border-bottom: 1px dotted #fc8019 ;
}
.header-wrapper-main .acc-info-wrap {
    justify-content: flex-end;
}
.user-info-wrap.cart-wrapper .info-icon {
    right: 26px;
}
.modal-backdrop.show {
    background: #000000;
    opacity: 0.9;
}

  

@keyframes headuser {
    0% {
        opacity: 0;
        transform: translate3d(0, 5px, 0);
    }
    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
@media only screen and (max-width: 992px) {
    .restaurent-ad .deals-banner-wrap .upper-banner {
        flex-direction: column-reverse;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-infoo {
        gap: 40px;
        padding: 30px;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn button {
        width: 50%;
    }
    .restaurent-ad .deals-banner-wrap {
        gap: 50px;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-img img {
        max-height: 300px;
        object-fit: cover;
    }
    .cstm-home-banner .swiper-button-prev {
        left: 20px;
    }
    .cstm-home-banner .swiper-button-next {
        right: 20px;
    }

    .header-mob-wrapper-main {
        display: block;
        padding: 20px;
    }
    .header-wrapper-main {
        display: none;
    }
    .custom-header .container {
        max-width: 100%;
    }
    .header-mob-wrapper-main .mob-logo,
    .header-mob-wrapper-main .mob-logo .logo-wrap,
    .header-mob-wrapper-main .mob-logo .login-btn,
    .header-mob-wrapper-main .mob-loc {
        padding: 0;
    }
    .header-mob-wrapper-main .mob-logo {
        margin-bottom: 15px;
    }
    .header-mob-wrapper-main .mob-logo .logo-wrap {
        text-align: center;
    }
    .row.mob-loc .search-bar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
    }
    .custom-header .location-wrap {
        font-size: 16px;
        text-align: right;
        padding: 0;
        padding-right: 20px;
        justify-content: flex-end;
    }
    .mob-logo .logo-wrap a {
        padding-left: 140px;
    }
}



@media only screen and (max-width: 767px) {
    .find-food-wrap .find-food-infoo .form-infoo {
        gap: 15px;
        justify-content: center;
    }
    .cstm-home-banner .swiper-slide.slide-item {
        height: 500px;
    }
    .about-us-slider .slide-item .right-side-content h1 {
        font-size: 40px;
    }
    .restaurent-ad .deals-banner-wrap {
        padding: 20px;
    }
    .cstm-home-banner .find-food-wrap {
        margin-top: 70px;
    }
    .cstm-home-banner .swiper-button-prev {
        left: 10px;
        top: 41%;
    }
    .cstm-home-banner .swiper-button-next {
        right: 10px;
        top: 41%;
    }
    .mob-loc .location-wrap {
        max-width: 92%;
        width: 92%;
        font-size: 14px;
        padding-right: 10px;
    }
    .mob-loc .search-bar {
        width: 5%;
        max-width: 5%;
    }
    .product-view-filter {
        flex-wrap: wrap;
        row-gap: 10px;
    }
}

@media only screen and (max-width: 520px) {
    .mob-loc .location-wrap {
        font-size: 14px;
        padding-right: 10px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .mob-logo .logo-wrap a {
        padding-left: 55px;
    }
}

@media only screen and (max-width: 480px) {
    .restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-cont h2 {
        font-size: 24px;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-infoo .banner-btn button {
        width: 90%;
    }
    .restaurent-ad .deals-banner-wrap .up-banner-infoo {
        gap: 25px;
        padding: 20px 20px 30px;
    }
}

@media only screen and (max-width: 370px) {
    .mob-logo .logo-wrap a {
        padding-left: 5px;
    }
    .header-mob-wrapper-main {
        padding: 20px 10px;
    }
    .header-mob-wrapper-main .mob-logo .logo-wrap {
        width: 60%;
        max-width: 60%;
    }
    .header-mob-wrapper-main .mob-logo .login-btn {
        width: 40%;
        max-width: 40%;
    }
}

@media only screen and (max-width: 281px) {
    .cstm-home-banner .find-food-wrap .find-text {
        padding: 10px;
    }
    .about-us-slider .slide-item .right-side-content h1 {
        font-size: 24px;
    }
    .cstm-home-banner .find-food-wrap .find-text p {
        text-align: center;
    }
    .restaurent-ad .deals-banner-wrap {
        padding: 10px;
    }
}

.b{
    border: 1px solid black;
}

.ProductView-image{
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.profile-section{
    /* background-color: #FFF; */
    width: 100%;
    height: 70vh;
}
.profile-section-UserThumb img{
  width: 200px;
  height: 200px;
  object-fit: cover;
  display: block;
  margin: auto;
}
.profile-section-address{
    background-color: #fff;
    box-shadow: 0 0 2px rgb(135, 135, 133);
    border-radius: 25px ;
    margin: 20px 0;
    padding: 10px 0;
}

.profile-order-food-thumb img{
   width: 150px;
   height: 100px;
   object-fit: cover;
   display: block;
   margin: auto;
}
.order-view-details{
    color: #fc8019 ;
    font-weight: bolder;
    cursor: pointer;
}
.order-status-profile span{
    color: #fc8019;
    font-weight: bolder;
    text-align: right;
}
.order-status-profile{
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
}
.order-content p {
    margin-bottom: 0;
}
.order-view-details {
    padding-top: 10px;
}

.order-box-profile{
    padding: 20px;
    border: none;
    border: 1px solid #ebebeb;
    background: #fff;
}
.text-align-end {
    text-align: end;
}
.profile-order-section{
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.profile-order-section::-webkit-scrollbar{
    display: none;
}
.profile-order-food-thumb {
    position: relative;
    overflow: hidden;
}

.profile-order-food-thumb img {
    transition: transform 0.3s ease;
}

.profile-order-food-thumb:hover img {
    transform: scale(1.1);
}
.order-status-profile-placed span{
    color: #18a72bbb;
    font-weight: bolder;
    text-align: right;
}
.order-status-profile-placed{
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
}
.order-btn button{
    padding: 5px 30px;
    font-size: 15px;
    font-weight: bold;
}
.order-btn .Track{
   background-color: #fc8019 ;
   color: #fff;
}
.order-btn .Help{
    padding: 4px 19px;
   border: 1px solid #fc8019 ;
   color: #000000;
}

.profile-main-left{
    background-color: #ebebeb;
    padding: 50px 0 50px 20px;
}

.profile-main-left-list{
    background-color: #fc8019 ;
    width: 80%;
    color: white;
    font-size: medium;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    /* margin: 10px 0; */
    display: block;
    margin: auto;
}
.profilemain-left-active{
    background-color: tomato !important;
    color: #fff !important;
}

.profile-user-name{
    color: #19164d;
}

.edit-profile-img img{
    width: 150px;
    height: 140px;
    cursor: pointer;
    border-radius: 100%;
    object-fit: cover;
}
.edit-profile-img{
    position: relative;
}
.pencil-profile-edit{
    position: absolute;
}

.slected-address-box{
    transition: 0.1s;
    transform: scale(1.05);
    box-shadow: 0 0 2px tomato;
    cursor: pointer;
}


a{
    color: #201c15 !important;  
}
a:hover {
    transition: 0.2s ease-in !important;
    color: tomato !important;
}

.empty-cart-header h2{
   margin-bottom: 8px;
}
.input_help_search_wrap {
    border: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    padding: 0 0 0 10px;
}
.input_help_search_wrap input {
    width: 100%;
    border: none;
    background-color: unset;

}
.input_help_search_wrap button {
    background: tomato;
}
.ProfileImage_outer_wrap {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    position: relative;
}
.edit_on_hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
}
.edit_on_hover i {
    font-size: 20px;
}
.ProfileImage_outer_wrap:hover {
    cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Change the color and opacity as needed */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Add smooth transition */
  }
  
  .ProfileImage_outer_wrap:hover .overlay {
    opacity: 1; /* Show overlay on hover */
  }
  
  
  .ProfileImage_outer_wrap:hover .edit_on_hover {
    display: block; /* Show edit icon on hover */
  }
.bg_style_profile_box {
    background: #fff;
    border-radius: 10px;
    padding: 15px 0;
    box-shadow: 0 0 10px tomato;
}
.add_address_box_wrap {
    background-color: #fff;
    box-shadow: 0 0 2px rgb(135, 135, 133);
    border-radius: 10px;
    margin: 20px 0;
    padding: 10px 0;
    cursor: pointer;
    width: 50%;
    margin: 50px auto;
}

