.offCanvasLogin {
  width: 40% !important; /* Default width for off-canvas login */
}
/* change phone number option  */
.text {
  display: flex;
  margin-left: 90px;
}

/* pointer cursor  */

.pointer {
  cursor: pointer !important;
  color: #eca918;
}
/* login-logo */

/* .logo-1{
    width: 170px;
    position: absolute;
    left: 154px;
}
.header-logo{
    display: flex;
    position: relative;
    margin-bottom: 65px;    
}
.login-text{
    font-size: 36px;
}
.line{
    color: black;
    z-index: 5;
    font-size: 50px;
    width: 40px;
} */

/* Small devices */
@media (max-width: 767px) {
  .offCanvasLogin {
    width: 100% !important;
    padding: 0 !important; /* Adjust padding for smaller screens */
    font-size: 30px;
    font-weight: 600;
  }
  .fw-changed {
    font-size: 30px;
  }
  .text {
    display: flex;
    margin-left: 162px;
  }
}

/* Medium devices (e.g., iPads) */
@media (min-width: 768px) and (max-width: 1024px) {
  .offCanvasLogin {
    width: 100% !important;
    padding: 0 !important; /* Adjust padding for medium screens */
    font-size: 30px;
    font-weight: 800;
  }
  .fw-changed {
    font-size: 82px;
    margin-top: 100px;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 75px;
  }
  .text-light-white {
    color: #6b6b83;
    font-size: 23px;
    font-weight: 600;
  }
  .form-control-submit {
    border: 1px solid #8f8fa1;
    border-radius: 4px;
    padding: 32px 16px;
  }
  .btn-submit {
    color: #fff;
    background: #fc8019 ;
    border-color: #fc8019 ;
    height: 63px;
    font-size: 20px;
    font-weight: 800;
  }
  .font-size-control {
    font-size: 23px;
  }
  .section-2,
  .main-banner {
    height: 180px 100%;
  }
  .text {
    display: flex;
    margin-left: 480px;
  }
}

.admin-toggle {
  display: flex;
  /* justify-content: space-around; */
  gap: 20px;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #eca918; /* Change the background color to yellow */
}

/* faq page style------------------- */


.faq-style{
  /* height: 35rem; */
  margin-bottom: 50px;
  width: 100%;
}

.faq-question{
  padding: 0px 0px 200px 0px;
}