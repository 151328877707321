.relative {
    position: relative;
}
.error-texts h1 {
    font-size: 133px;
    font-weight: 900;
    margin-bottom: 20px;
}
.error-texts h3{
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
}
.error-texts p{
    font-size: 16px;
    margin-bottom: 20px;
}
.alert.d-block{
    display: block!important;
}
span.custm-cls {
    font-size: 12px;
}



.shadowed-div {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.);
    border-bottom: none;
  }




  /* -----modal css starts---- */



   hr {
    border: none; /* Remove the default border */
    height: 0.3px; /* Set the height of the horizontal line */
    background-color: #d5d5d5; /* Set the background color */
    margin: '-20px 0';
  }





.add-address:hover{
 background-color: rgba(204, 204, 204, 0.208);
}


/* -----------------switch buttons------------- */
.custom-control-input:focus~.custom-control-label::before {
    border-color: red !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
  }
  
  .custom-control-input:checked~.custom-control-label::before {
    border-color: red !important;
    background-color: red !important;
  }
  
  .custom-control-input:active~.custom-control-label::before {
    background-color: red !important;
    border-color: red !important;
  }
  
  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: red !important;
  }
  
  .custom-control-input-fgreen:not(:disabled):active~.custom-control-label::before {
    background-color: red !important;
    border-color: red !important;
  }




  /* ------payment method page-------------------------------- */
  .checkout-Accordion-bg:hover{
    color: white;
    background-color: #fc8019 ;
    height: 40px;
  }
  

  .selected-card {
    border: 0.5px solid red;
  }



  /* -------storeview page hover box-------------------------------- */
  /* .product-box-hover:hover{
    box-shadow:0 0 15px red;
  } */

 .product-view-filter-btn{
  background-color: #fff !important;
  border: 0.001rem solid #959595;
  border-radius: 30px;
  margin: 0 10px;
  padding: 7px 15px;
  height: 40px;
  justify-content: center;
 }
 .product-view-filter-btn:hover{
  border: 0.001rem solid #fc8019 ;
  cursor: pointer;
 }


 /* ---sort body */

 .product-view-filter-btn ul li .dropdown-item{
  padding: 10px 5px;
 }
 .product-view-filter-btn ul li .dropdown-item:hover{
  background-color: white !important;
  cursor: pointer;
 }
 .product-view-filter-btn ul li .dropdown-item input{
  position:absolute;
  right:5px;
  width:18px;
  height:18px;
  accent-color:#fc8019  !important;
 }
 
 .product-view-filter-btn ul .apply_btn{
  color:#fc8019 ;
  text-align: end;
 }
 .product-view-filter-btn ul .apply_btn:hover{
  color:#d70217;
 }


 /* .accordion-div{
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px solid black;
 }


 .accordion_list_sortBy{
  position:absolute;
  right:5px;
 }
 .accordion_list_sortBy input{
  position:absolute;
  left:5px !important;
  accent-color:#fc8019  !important;
 } */

 /* CSS styles */
.accordion-div {
  display: flex;
  flex-direction: column;
}

.accordion_list_sortBy {
  list-style: none;
  margin: 5px; /* Adjust margin as needed */
}

input[type="radio"] {
  float: left;
  margin-right: 10px; /* Adjust margin as needed */
  accent-color:#fc8019  !important;
  width:18px;
  height:18px;
}

.radio-label {
  float: right;
  text-align: right;
  /* Optionally, you can add styles for label width, font size, etc. */
}


.footer {
  background-color: 	#DC3545;
  padding: 10px 20px;
  position: fixed;
  bottom: -53px;;
  width: 73.1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  transition: bottom 0.3s ease;
}

.show-footer {
  bottom: 0; 
}




