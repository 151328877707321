/* .footer-1{
    background-color: gray !important;

}
.footer-2{
    background-color: gray !important;
} */
.btn-edit {
    background-color: #fc8019  !important;
    height: 38px;
    /* margin-left: -25px; */
}

.btn-edit:hover {
    background-color: #FF715B !important;
    border-radius: none !important;
    transition: none;
    border: none;
    
}
.form-edit{
    padding: 0px 70px 20px 70px;
}



